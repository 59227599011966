import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [2];

export const dictionary = {
		"/[[lang]]/about": [~4,[2]],
		"/[[lang]]/blog": [~5,[2]],
		"/[[lang]]/blog/[slug]": [~6,[2]],
		"/[[lang]]/contact": [~7,[2]],
		"/[[lang]]/cookies": [~8,[2]],
		"/[[lang]]/gdpr": [~9,[2]],
		"/[[lang]]/landing/[slug]": [~10,[2]],
		"/[[lang]]/policy": [~11,[2]],
		"/sentry-example": [14],
		"/[[lang]]/work": [~12,[2]],
		"/[[lang]]/work/[slug]": [~13,[2]],
		"/[[lang]]": [~3,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';